@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.steps__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
  text-align: center;
  
  &_wide-content {
    max-width: 970px;
    margin: 0 auto !important;
    + div {
      padding: 40px 45px;
    }
  }
}

.all-light .tabgroup p {
	color: rgba($white, .6);
}

.steps__wrapper-gradient {
	position: relative;
	z-index: 1;
	margin: 0 5px;
	@media(min-width: 992px) {
		margin: 0 auto;
		width: calc(100% - 30px);
	}
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 50%;
		transform: translate(-50%, 50%);
		width: 100%;
		height: 8px;
		background: #f7f7f7;
		box-shadow: inset 0 1px 15px -8px $black;
		z-index: -1;
	}
	li {
		position: relative;
		&.complete .steps__dot {
			background: var(--brand-primary);
      border-color: var(--brand-primary);
      &:before,
      .after {
        background: var(--brand-primary);
      }
		}
		> a.active .steps__dot {
			border-color: var(--brand-primary);
      background: $white;
      &:before,
      .after {
        background: #fff;
      }
		}
	}
	.steps__title {
		color: $black;
		position: absolute;
		top: -26px;
		left: 50%;
		transform: translate(-50%, -100%);
		min-width: 60px;
	}
	.steps__progress {
		height: 4px;
		background: var(--brand-primary);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all .4s ease;
		left: 0;
	}
	.steps__dot {
		display: block;
		margin: 0 auto;
		width: 26px;
		height: 26px;
		border: 3px solid #F4F4F4;
		background: #F4F4F4;
		border-radius: 50%;
		position: relative;
		z-index: 1;
		transition: all .3s ease .3s;
	}
}

.all-light .steps__wrapper-gradient {
	&:before {
		background: $white;
		box-shadow: none;
	}
	li {
		&.complete .steps__dot > .before {
			left: -3px;
			width: 35px;
			transition-delay: .25s;
		}
		&:first-child .steps__dot > .before {
			left: 0;
			width: 30px;
		}
		&.complete .active .steps__dot > .before {
			width: 4px;
		}
	}
	.steps__title {
		color: $white;
	}
	.steps__dot {
		border: none;
		> .before {
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			transition: all .4s ease;
			height: 4px;
			width: 10px;
			background: var(--brand-primary);
		}
		> .after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border: 3px solid var(--brand-primary);
			border-radius: 50%;
		}
		&:before {
			content: '';
			position: absolute;
			top: 3px;
			left: 3px;
			right: 3px;
			bottom: 3px;
			background: $white;
			border-radius: 50%;
			z-index: 2;
		}
		&:after {
			content: '';
			position: absolute;
			top: -3px;
			left: -3px;
			right: -3px;
			bottom: -3px;
			background: $white;
			border-radius: 50%;
			z-index: -1;
		}
	}
}

.steps__wrapper-history {
	position: relative;
	z-index: 1;
	margin: 0 5px;
	@media(min-width: 992px) {
		margin: 0;
	}
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 50%;
		transform: translate(-50%, 50%);
		width: 100%;
		height: 8px;
		background: #f7f7f7;
		z-index: -1;
	}
	li {
		position: relative;
		&.complete {
		}
		> a.active .steps__dot {
			background: var(--secondary);
		}
		> a.active .steps__title {
			opacity: 1;
		}
		&:first-child, &:last-child {
			width: 14px;
			.steps__dot {
				display: none;
			}
			.steps__title {
				position: absolute;
				padding: 3px;
				width: 50px;
				top: 50%;
				left: auto;
				right: auto;
				bottom: auto;
				transform: translateY(-50%);
				opacity: 1;
				@media(min-width: 768px) {
					width: 82px;
					padding: 5px;
				}
				> .after {
					display: none;
				}
			}
		}
		&:first-child .steps__title {
			left: -1px;
		}
		&:last-child .steps__title {
			right: -2px;
		}
	}
	.steps__title {
		position: absolute;
		top: -15px;
		left: 50%;
		transform: translate(-50%, -100%);
		min-width: 60px;
		padding: 3px 18px;
		color: $white;
		background: var(--brand-primary);
		border-radius: 50px;
		box-shadow: 0 5px 16px 0 rgba($black, 0.15);
		opacity: 0;
		transition: all .4s ease;
		> .after {
			position: absolute;
			bottom: -7px;
			left: 50%;
			transform: translateX(-50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 7px 7px 0 7px;
			border-color: var(--brand-primary) transparent transparent transparent;
		}
	}
	.steps__progress {
		height: 4px;
		background: var(--brand-primary);
		background: linear-gradient(to right, var(--brand-primary), var(--secondary));
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all .4s ease;
		left: 0;
	}
	.steps__dot {
		display: block;
		margin: 0 auto;
		width: 14px;
		height: 14px;
		border: 2px solid #F4F4F4;
		background: var(--brand-primary);
		border-radius: 50%;
		position: relative;
		z-index: 1;
		box-shadow: 0 2px 10px 0 rgba($black, 0.4);
	}
}

.all-light .steps__wrapper-history {
	.steps__title {
		background: $white;
		color: var(--brand-primary);
		> .after {
			border-color: $white transparent transparent transparent;
		}
	}
}

.steps__wrapper-checkers {
	position: relative;
	z-index: 1;
	margin: 0 7px;
	@media(min-width: 992px) {
		margin: 0;
		margin-bottom: 30px;
	}
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 50%;
		transform: translate(-50%, 50%);
		width: calc(100% - 2px);
		height: 8px;
		background: #E8F0FE;
		box-shadow: inset 0 1px 15px -8px $black;
		z-index: -1;
	}
	li {
    cursor: pointer;
		position: relative;
		&.complete .steps__dot {
			background: var(--brand-primary);
			color: transparent;
			&:before {
				color: $white;
			}
		}
		&:not(.complete) .steps__dot {
			background: #E8F0FE !important;
		}
		&:last-child {
			margin-right: -1px;
		}
	}
	.steps__title {
		color: $gray-dark;
		position: absolute;
		top: 45px;
		left: 50%;
		transform: translate(-50%, 0%);
		min-width: 80px;
	}
	.steps__progress {
		height: 2px;
		background: var(--brand-primary);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all .4s ease;
		left: 0;
	}
	.steps__dot {
		margin: 0 auto;
		color: $gray-dark;
		width: 30px;
		height: 30px;
		border: 4px solid #E8F0FE;
		transition: all .4s ease;
		border-radius: 50%;
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		&:before {
			content: '\f00c';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all .4s ease;
      font-family: $fa;
      font-weight: 600;
			font-size: 14px;
			color: transparent;
			z-index: 20;
		}
		> .before {
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			transition: all .4s ease;
			height: 2px;
			width: 10px;
			opacity: 0;
			background: var(--brand-primary);
		}
		> .after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: var(--brand-primary);
			z-index: 2;
			border-radius: 50%;
			opacity: 0;
			transition: all .4s ease;
		}
		&:after {
			content: '';
			position: absolute;
			top: -4px;
			left: -4px;
			right: -4px;
			bottom: -4px;
			border: 5px solid #E7EFFD;
			border-radius: 50%;
			z-index: -1;
		}
	}
	li {
		&.complete .steps__dot > .after {
			opacity: 1;
		}
		&.complete .steps__dot > .before {
			left: -4px;
			width: 45px;
			opacity: 1;
			transition-delay: .25s;
		}
		&:first-child .steps__dot > .before {
			left: 0;
			width: 45px;
			opacity: 1;
		}
		&.complete .active .steps__dot > .before {
			width: 4px;
		}
	}
	.steps__dot {
		border: none;
	}
}

.all-light .steps__wrapper-checkers {
	.steps__title {
		color: $white;
	}
	&:before {
		box-shadow: none;
	}
}

.steps__wrapper-squared {
	position: relative;
	z-index: 1;
	margin: 0 5px;
	@media(min-width: 992px) {
		margin: 0;
	}
	li {
		position: relative;
		> a.active .steps__dot {
			background: $white;
			> .after {
				border-color: var(--brand-primary);
			}
		}
		> a.active .steps__icon {
			border-color: var(--brand-primary);
			color: var(--brand-primary);
			background: $white !important;
			> .after {
				border-color: var(--brand-primary) transparent transparent transparent;
			}
		}
		> a.active .steps__title {
			opacity: 1;
		}
  }
  &_dark {
    li {
      > a.active .steps__dot {
        > .after {
          border-color: var(--secondary);
          background-color: transparent;
        }
      }

      > a.active .steps__icon {
        border-color: var(--secondary);
        color: #fff;
        background: transparent !important;
        > .after {
          border-color: var(--secondary) transparent transparent transparent;
        }
      }
    }
  }
	.steps__icon {
		position: absolute;
		top: -20px;
		left: 50%;
		transform: translate(-50%, -100%);
		transition: all .4s ease;
		font-size: 20px;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		background: var(--brand-primary);
		border: 3px solid var(--brand-primary);
		@media(min-width: 992px) {
			width: 100px;
			height: 100px;
			font-size: 46px;
		}
		> .after {
			position: absolute;
			bottom: -16px;
			left: 50%;
			transform: translateX(-50%);
			transition: all .4s ease;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 16px 16px 0 16px;
			border-color: var(--brand-primary) transparent transparent transparent;
		}
  }
  &_dark {
    .steps__icon { 
      background: var(--secondary);
      border: 3px solid var(--secondary);

      > .after {
  			border-color: var(--secondary) transparent transparent transparent;
      }
    }
  }
	.steps__title {
		color: $gray-dark;
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translate(-50%, 100%);
		min-width: 60px;
		opacity: 0.4;
  }
  &_dark {
    .steps__title {
      color: #fff;
    }
  }
	.steps__progress {
		height: 3px;
		background: var(--brand-primary);
		background: linear-gradient(to right, var(--brand-primary), var(--secondary));
		width: calc(100% - 1px) !important;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all .4s ease;
    left: 0;
  }
  &_dark {
    .steps__progress {
      background: var(--secondary);
    }
  }
	.steps__dot {
		display: block;
		margin: 0 auto;
		width: 22px;
		height: 22px;
		border: 5px solid $white;
		background: var(--brand-primary);
		border-radius: 50%;
		position: relative;
		z-index: 1;
		> .after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border: 2px solid var(--brand-primary);
			border-radius: 50%;
			z-index: -1;
		}
  }
  &_dark {
    .steps__dot {
      > .after {
        border: 2px solid var(--secondary);
        background-color: var(--secondary);
      }
    }
  }
  &_light {
    .steps__icon {
      background: $white;
      border-color: $white;
      color: var(--brand-primary);
      > .after {
        border-color: $white transparent transparent transparent;
      }
    }
    .steps__progress {
      background: $white;
    }
    .steps__title {
      color: $white;
    }
    .steps__dot {
      border-color: var(--brk-base-3);
      background: $white;
      > .after {
        border-color: $white;
      }
    }
    li {
      > a.active .steps__dot {
        background: var(--brand-primary);
        > .after {
          border-color: $white;
        }
      }
      > a.active .steps__icon {
        border-color: $white;
        color: $white;
        background: transparent !important;
        > .after {
          border-color: $white transparent transparent transparent;
        }
      }
    }
  }
}

.steps-content {
  &_dark {
    background-color: var(--secondary);
    .steps-content {
      &__title {
        color: #fff;
      }
      &__description {
        color: rgba(256,256,256, .6);
      }
    }
  }
}

.steps__wrapper-light {
	position: relative;
	z-index: 1;
	margin: 0 5px;
	@media(min-width: 992px) {
		margin: 0;
	}
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 50%;
		transform: translate(-50%, 50%);
		width: 100%;
		height: 3px;
		background: #f7f7f7;
		box-shadow: inset 0 1px 15px -8px $black;
		z-index: -1;
	}
	li {
		display: inline-block;
		position: relative;
		flex: 1;
		margin-top: -80px;
		> a {
			display: inline-block;
		}
		&.complete .steps__dot {
			background: var(--brand-primary);
			border-color: var(--brand-primary);
		}
		> a.active .steps__dot {
			border-color: var(--brand-primary);
			background: $white;
		}
	}
	.steps__title {
		color: $gray-dark;
		.icon {
			display: block;
			font-size: 21px;
			vertical-align: baseline;
			color: var(--brand-primary);
			padding-right: 5px;
			@media(min-width: 480px) {
				display: inline-block;
			}
		}
	}
	.steps__progress {
		height: 3px;
		background: var(--brand-primary);
		background: linear-gradient(to right, var(--brand-primary), var(--secondary));
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all .4s ease;
		left: 0;
	}
}

.all-light .steps__wrapper-light {
	&:before {
		background: $white;
		opacity: 0.4;
		box-shadow: none;
	}
	.steps__title {
		color: $white;
		.icon {
			color: $white;
		}
	}
	.steps__progress {
		background: $white;
	}
}